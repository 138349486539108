
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import roomProfitCalculateType from "@/core/data/roomProfitCalculateType";

interface newItemData {
    itemTitle: string;
    itemDate: string;
    itemMarket: number;
    itemPrice: string;
    itemForced: string;
}

export default defineComponent({
    name: "new-over-commission-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    mounted() {
        this.datapicker();
        return true;
    },
    methods: {
        datapicker() {
            let config = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
            };
            this.startpicker = flatpickr("#kt_datepicker_1", config);
            this.endpicker = flatpickr("#kt_datepicker_2", config);
        },
    },
    props: {
        hotelID: String,
    },
    setup() {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);


        const myDetails2 = computed(() => {
            return store.getters.hotelDetails;
        });



        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemDate: "",
            itemMarket: 1,
            itemPrice: "",
            itemForced: "",
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Etkinlik Başlığı"),
            itemDate: Yup.string().required().label("Etkinlik Tarihi"),
            itemForced: Yup.string().required().label("Zorunluluk Durumu"),
            itemPrice: Yup.string().required().label("Fiyat Girilmemiş"),
        });
       /* const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            const payload1 = {
                Hotels: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                Markets: JSON.stringify(newItemData.value.itemMarket),
                Accounts: JSON.stringify(newItemData.value.itemAccount),
                Type: newItemData.value.itemType,
                CalculateType: newItemData.value.itemCalculateType,
                Amount: newItemData.value.itemAmount,
            }
            //console.log(payload1);

            store.dispatch(Actions.HOTEL_PROFITS_INSERT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", confirmButtonText: "Geri Dön!",
                        icon: "success", buttonsStyling: false,  customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getHotelProfitsErrors[0], confirmButtonText: "Tekrar dene!",
                        icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                    });
                    submitButtonRef.value.disabled = false;
                    submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                });

        };*/

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });



        return {
            myDetails2,
            newItemData,
            validationSchema,
            //submit,
            submitButtonRef,
            newRoomModalRef,
            roomProfitType,
            roomProfitCalculateType,
            dateConvert,
          myMarkets,
        };
    },
});
