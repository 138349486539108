
import {computed, defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";

import EventAddNew from "@/components/hotel/EventAddNew.vue";

export default defineComponent({
    name: "overview",
    components: {
      EventAddNew,
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Otel Etkinlikleri", ["Otel"]);
        });

        const route = useRoute();
        const id = route.params.id;
        const payload = {
            ID: id,
        }
        //console.log(payload);
        store.dispatch(Actions.HOTEL_DETAILS, payload);
        const myDetails = computed(() => {
            return store.getters.hotelDetails;
        });




        return {
            id,
            myDetails,
            dateConvert,
        };
    },
});
